import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Lottie from "lottie-react";
import { useCookies } from "react-cookie";
import detectBrowserLanguage from "detect-browser-language";
import * as animationOrientation from "./anim_orientation.json";
import {
  faTimeline,
  faInfo,
  faCircle,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faPlayCircle,
  faVolumeXmark,
  faVolumeHigh,
  faPlay,
  faPause,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState, useRef, useCallback } from "react";
import TagManager from "react-gtm-module";
import { library } from "@fortawesome/fontawesome-svg-core";
import "./App.css";
import Logo from "./images/CAR ROUGE LOGO SEB.svg";
import Home from "./images/MAISONFichier 8.svg";
import FlagFr from "./images/DRAPEAU FRANCEFichier 13.svg";
import FlagEn from "./images/DRAPEAU ENGFichier 14.svg";
import Timeline from "./images/NAVIG TEMP PICTO.svg";
import TimelineWhite from "./images/NAVIG TEMP PICTO white.svg";
import PictoAvant from "./images/PICTO_AVANT.svg";
import PictoArriere from "./images/PICTO_ARRIERE.svg";

library.add(faTimeline, faCircle, faChevronDown);

const tagManagerArgs = {
  gtmId: "GTM-TPPPJXS",
};

function App() {
  const [browserLanguage] = useState(detectBrowserLanguage());
  const [cookies, setCookie] = useCookies(["consent"]);
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const [globalCoords, setGlobalCoords] = useState({ x: 0, y: 0 });
  const [language, setLanguage] = useState("");
  const [videoLoop, setVideoLoop] = useState(false);
  const [videoMuted, setVideoMuted] = useState(true);
  const [videoPaused, setVideoPaused] = useState(false);
  const [overlay, setOverlay] = useState(true);
  const [infos, setInfos] = useState(false);
  const [displayMenu, setDisplayMenu] = useState(false);
  const [displayMenuButton1, setDisplayMenuButton1] = useState(false);
  const [displayMenuButton2, setDisplayMenuButton2] = useState(false);
  const [displayMenuButton3, setDisplayMenuButton3] = useState(false);
  const [displayTimeline, setDisplayTimeline] = useState(false);
  const [step, setStep] = useState(1);
  const [video, setVideo] = useState("00_ATTENTE");
  const [screenOrientation, setScreenOrientation] = useState("portrait");
  const [showInfoPage, setShowInfoPage] = useState("btn");
  const [backPage, setBackPage] = useState(null);
  let mql = window.matchMedia("(orientation: portrait)");

  const videoRef = useRef();
  const maxSteps = 11;

  mql.addEventListener("change", function (m) {
    if (m.matches) {
      setScreenOrientation("portrait");
    } else {
      setScreenOrientation("landscape");
    }
  });

  useEffect(() => {
    // changeScreenOrientation();
    if (mql.matches) {
      setScreenOrientation("portrait");
    } else {
      setScreenOrientation("landscape");
    }
    getStepVideo();
    setGlobalCoords({
      x: window.innerWidth,
      y: window.innerHeight,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMouseMove = (event) => {
    if (step === 2 || step === 3) {
      setCoords({
        x: event.clientX - event.target.offsetLeft,
        y: event.clientY - event.target.offsetTop,
      });
    }
  };

  useEffect(() => {
    if (step === 2 || step === 3) {
      const half = globalCoords.x / 2;
      if (coords.x < half && language !== "fr") {
        setLanguage("fr");
      } else if (coords.x > half && language !== "en") {
        setLanguage("en");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coords, globalCoords]);

  useEffect(() => {
    getStepVideo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 32 && !videoRef.current.paused) {
        handlePaused(false);
      } else if (e.keyCode === 32 && videoRef.current.paused) {
        handlePaused(true);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (cookies.consent && cookies.consent === "yes") {
      TagManager.initialize(tagManagerArgs);
      setOverlay(false);
    } else if (cookies.consent && cookies.consent === "no") {
      setOverlay(false);
    }
  }, [cookies]);

  useEffect(() => {
    if (browserLanguage && browserLanguage.split("-")[0] === "fr") {
      setLanguage("fr");
    } else if (browserLanguage && browserLanguage.split("-")[0] !== "fr") {
      setLanguage("en");
    }
  }, [browserLanguage]);

  const handlePaused = (choice) => {
    if (choice) {
      videoRef.current.play();
      setVideoPaused(false);
    } else {
      videoRef.current.pause();
      setVideoPaused(true);
    }
  };

  const handleRgpd = (choice) => {
    setCookie("consent", choice, { path: "/", maxAge: 86400 * 180 });
    setOverlay(false);
    if (choice) {
      TagManager.initialize(tagManagerArgs);
    }
  };

  const handleInfos = () => {
    if (infos) {
      videoRef.current.play();
      setShowInfoPage("btn");
    } else {
      videoRef.current.pause();
    }
    setInfos(!infos);
  };

  const handleMenu = () => {
    if (step > 1) {
      if (!displayMenu) {
        setDisplayMenuButton3(true);
        setDisplayMenuButton2(true);
        setDisplayMenuButton1(true);
      } else {
        setDisplayMenuButton1(false);
        setDisplayMenuButton2(false);
        setDisplayMenuButton3(false);
      }
      setDisplayMenu(!displayMenu);
    }
  };

  const showMenu = () => {
    if (!displayMenu) {
      setDisplayMenuButton3(true);
      setDisplayMenuButton2(true);
      setDisplayMenuButton1(true);
      setDisplayMenu(true);
    }
  };

  const hideMenu = () => {
    if (displayMenu) {
      setDisplayMenuButton3(false);
      setDisplayMenuButton2(false);
      setDisplayMenuButton1(false);
      setDisplayMenu(false);
    }
  };

  const handleStep = (newStep = null) => {
    if (overlay) setOverlay(false);
    if (newStep === "play") {
      newStep = null;
      handleUnMuted(false);
    }
    if (Number(newStep)) {
      setStep(newStep);
      if (Number(newStep) === 1) {
        setDisplayMenu(false);
        setDisplayMenuButton1(false);
        setDisplayMenuButton2(false);
        setDisplayMenuButton3(false);
        setDisplayTimeline(false);
      }
    } else if (step < maxSteps) {
      if (step + 1 === 2 && language !== "") {
        setStep(4);
      } else if (step + 1 === 3 && language !== "") {
        setStep(4);
      } else {
        setStep(step + 1);
      }
    } else if (step === maxSteps) {
      setStep(1);
    }
  };

  const getStepVideo = useCallback(() => {
    setVideoLoop(false);
    let videoName = "";
    switch (step) {
      case 1:
        videoName = "00_ATTENTE";
        setVideoLoop(true);
        break;
      case 2:
        videoName = "01_LANGUES_1";
        break;
      case 3:
        videoName = "01_LANGUES_2";
        setVideoLoop(true);
        break;
      case 4:
        videoName = "02_MODE_D_EMPLOI_" + language.toUpperCase();
        break;
      case 5:
        videoName = "03_PERIODE_1_" + language.toUpperCase();
        break;
      case 6:
        videoName = "04_PERIODE_2_" + language.toUpperCase();
        break;
      case 7:
        videoName = "05_PERIODE_3_" + language.toUpperCase();
        break;
      case 8:
        videoName = "06_PERIODE_4_" + language.toUpperCase();
        break;
      case 9:
        videoName = "07_PERIODE_5_" + language.toUpperCase();
        break;
      case 10:
        videoName = "08_PERIODE_6_" + language.toUpperCase();
        break;
      case 11:
        videoName = "09_FIN_" + language.toUpperCase();
        break;
      default:
        videoName = "00_ATTENTE";
        setVideoLoop(true);
    }

    if (
      displayMenu ||
      displayMenuButton1 ||
      displayMenuButton2 ||
      displayMenuButton3
    ) {
      setDisplayMenu(false);
      setDisplayMenuButton1(false);
      setDisplayMenuButton2(false);
      setDisplayMenuButton3(false);
    }

    setVideo(videoName);
    if (screenOrientation?.includes("landscape")) {
      setTimeout(() => {
        videoRef.current.load();
      }, 1);
      if (step > 1) {
        videoRef.current.muted = videoMuted;
      } else {
        videoRef.current.defaultMuted = true;
        videoRef.current.muted = true;
      }
    }
  });

  useEffect(() => {
    if (step > 3) {
      getStepVideo();
    }

    if (language === "fr") {
      document.title =
        "SEB Discovery - Découvrez l’incroyable histoire du Groupe SEB";
    } else {
      document.title =
        "SEB Discovery - Discover the amazing history of Groupe SEB";
    }
  }, [language]);

  const handleUnMuted = (value) => {
    videoRef.current.muted = value;
    setVideoMuted(value);
  };

  const handleTimeUpdate = (time) => {
    if (
      step === 4 &&
      time.target.currentTime > 39.73 &&
      time.target.currentTime <= 40 &&
      !displayMenu
    ) {
      setDisplayMenu(true);
      setDisplayMenuButton1(true);
      setDisplayMenuButton2(true);
      setDisplayMenuButton3(true);
    } else if (
      step === 4 &&
      time.target.currentTime > 46 &&
      time.target.currentTime <= 48 &&
      displayMenu
    ) {
      setDisplayMenu(false);
      setDisplayMenuButton1(false);
      setDisplayMenuButton2(false);
      setDisplayMenuButton3(false);
    }
  };

  const handleFlagHover = (e) => {
    e.target.src = language === "en" ? FlagFr : FlagEn;
  };

  const handleFlagOut = (e) => {
    e.target.src = language === "en" ? FlagEn : FlagFr;
  };

  const handleBackward = () => {
    const currentTime = videoRef.current.currentTime;
    const nextTime = currentTime - 10;
    if (nextTime > 0) {
      videoRef.current.currentTime = nextTime;
    }
  };

  const handleForward = () => {
    const currentTime = videoRef.current.currentTime;
    const nextTime = currentTime + 10;
    if (nextTime < videoRef.current.duration) {
      videoRef.current.currentTime = currentTime + 10;
    } else {
      handleStep();
    }
  };

  const handleChangePage = () => {
    if (backPage || showInfoPage === "btn") {
      setInfos(false);
      setBackPage(null);
    } else {
      setShowInfoPage("btn");
    }
  };

  const InfoPageEN = () => {
    if (showInfoPage === "btn") {
      return (
        <div>
          <div className="containerGrid">
            <div>
              <button onClick={() => setShowInfoPage("cgu")}>
                {language === "fr"
                  ? "Conditons générales d'utilisation"
                  : "General terms and conditions of use"}
              </button>
            </div>
            <div>
              <button onClick={() => setShowInfoPage("confidentialite")}>
                {language === "fr"
                  ? "Politique de confidentialité"
                  : "Privacy policy"}
              </button>
            </div>
            <div>
              <button onClick={() => setShowInfoPage("cookies")}>
                {language === "fr" ? "Politique Cookies" : "Cookies policy"}
              </button>
            </div>
            <div>
              <button onClick={() => setShowInfoPage("cookies_center")}>
                {language === "fr" ? "Gestion des Cookies" : "Manage Cookies"}
              </button>
            </div>
            <div>
              <button onClick={() => setShowInfoPage("mentions")}>
                {language === "fr" ? "Mentions légales" : "Legal notice"}
              </button>
            </div>
            <div>
              <button onClick={() => setShowInfoPage("copy")}>
                {language === "fr" ? "Copyrights" : "Copyrights"}
              </button>
            </div>
          </div>
        </div>
      );
    } else if (showInfoPage === "cgu") {
      return (
        <div>
          <h1 style={{ fontWeight: "bold" }}>
            General terms and conditions of use
          </h1>
          Applicable as from December 1st, 2022
          <br />
          <br />
          <h3>1. PREAMBLE</h3>
          These General Terms and Conditions of Use inform you of all the rules
          applicable to using the website{" "}
          <a href="https://discovery.groupeseb.com/">
            https://discovery.groupeseb.com/
          </a>{" "}
          published by SEB DEVELOPPEMENT hereinafter the Website.
          <br />
          <br />
          The purpose of the Site is to provide institutional and historical
          information about Groupe SEB.
          <br />
          <br />
          To accurately determine the legal entity responsible for the Website
          you use, please consult the <b>Legal Notice</b> available on the
          Website.
          <br />
          <br />
          These general terms and conditions of use (CGU) may be supplemented by
          particular conditions specific to the Website.
          <br />
          <br />
          The terms of use refer to the following supplementary conditions,
          which also apply to your use of our Website:
          <br />- <b>Privacy Policy</b>
          <br />- <b>Legal Notices</b>
          <br />
          <br />
          Please read these supplementary documents that are available on this
          Website.
          <br />
          <h3>2. GLOSSARY</h3>
          <b>What do these terms mean?</b>
          <br />
          <br />
          <b>CGU</b> : refers to these general terms and conditions of use
          governing use of the Website
          <br />
          <br />
          <b>Content</b> : refers to all audio, video, image and text files,
          information, databases, concepts, brands, logos, technical and graphic
          solutions, appearance, structure and any other element whether or not
          it is protected by intellectual property rights or by existing or
          future legal provisions, that we communicate on the Website and/or
          available to you on the Website.
          <br />
          <br />
          <b>Brand(s)</b> : refers to trademarks, logos, brands and any other
          name owned by Groupe SEB.
          <br />
          <br />
          <b>We, our</b> : refers to Groupe SEB, i.e. all the companies directly
          or indirectly attached to SEB SA.
          <br />
          <br />
          <b>Service(s)</b> : refers, individually or collectively, to any
          service and all its associated functions that we provide through the
          Website free of charge or in exchange for payment.
          <br />
          <br />
          <b>Website</b> : refers, where applicable, to this website
          <br />
          <br />
          <b>You, your</b> : refers to any individual or legal entity accessing,
          navigating or visiting the Website in a professional or personal
          capacity.
          <br />
          <br />
          <h3>3. ACCEPTANCE AND MODIFICATION OF THE CGU</h3>
          By accessing the Website, you agree to comply with the CGU, and all
          the conditions contained or mentioned therein, in force on the day of
          access.
          <br />
          <br />
          If you refuse any of the obligations or conditions contained in the
          CGU, please do not use the Website. We may modify all or part of the
          Website, Services and/or CGU at any time.
          <br />
          <br />
          <h3>4. WEBSITE ACCESS CONDITIONS</h3>
          We grant you a non-exclusive, non-transmittable, non-transferable
          right to use the Website's Content for the sole purpose of using the
          Website in accordance with the CGU.
          <br />
          <br />
          You undertake to comply with the CGU and the legal obligations in
          force.
          <br />
          <br />
          In particular, you undertake to comply with the following rules:
          <br />
          <br />
          <ul>
            <li>
              Use the Website in an honest manner in accordance with its
              purposes;
            </li>
            <li>
              Do not copy all or part of the Website or reproduce it, sell it,
              make it available under a licence, distribute it, assign it,
              modify it, adapt it, translate it, produce derived works,
              deconstruct it, submit it to inverse engineering operations,
              disassemble it or attempt to identify its source code;
            </li>
            <li>
              Do not circumvent or thwart the rules governing security or use of
              Content that are provided, deployed or applied by a feature of the
              Website;
            </li>
            <li>
              Not to use a virus, robot, software or subprogramme to interfere
              with or attempt to interfere with the proper operation of the
              Website or with the use of the Website by any other person;
            </li>
            <li>
              Do not publish or enter inaccurate or misleading information when
              using the Website
            </li>
            <li>Do not usurp the identity of any other person;</li>
            <li>
              Do not collect information from other users with the intention of
              using it, including for sending business offers or similar, or
              including it in a referencing service.
            </li>
          </ul>
          <br />
          <br />
          <h3>5. INTELLECTUAL PROPERTY</h3>
          The Content comprising the Website and the associated rights are the
          property of the Groupe SEB, including, without this list being
          exhaustive, the Brands, designs, appearances, structure, concepts,
          technical and graphic solutions, databases, software and their source
          codes, as well as the layout and format of these elements, comprising
          the Website and Services, and/or being the basis for the operation of
          the Website and Services and/or transiting through the Website and
          Services, and/or provided through the Website and Services.
          <br />
          <br />
          Any reproduction, representation, adaptation, modification,
          publication, transmission, distortion or inclusion in another website,
          commercial operation and/or total or partial reuse of the Website
          and/or its Content, by any method and on any medium, is strictly
          prohibited and may constitute an act of counterfeiting sanctioned by
          the French Intellectual Property Code or any other applicable
          regulation.
          <br />
          <br />
          <h3>6. PERSONAL DATA</h3>
          The potential collection and processing of Your personal data are
          governed by the Website's <b>Privacy Policy</b>. Please read it.
          <br />
          <br />
          <h3>7. LIABILITY</h3>
          <b>Who is responsible for links to other websites?</b>
          <br />
          <br />
          The Website may provide access to other websites and/or applications
          via hypertext links. We are not responsible for such websites and
          applications and have no control over their content.
          <br />
          <br />
          As a result, we cannot under any circumstances be held liable for:
          <br />
          <br />
          <ul>
            <li>
              The content and services made available on such applications
              and/or third-party sites, or
            </li>
            <li>
              Any collection and transmission of data, installation of cookies
              or any other procedure with the same purposes, carried out by such
              websites or applications, or
            </li>
            <li>
              Any issues with the security or integrity of data, hardware or
              software that you may encounter, as well as any consequences or
              damage resulting from the use of such applications and/or
              third-party websites.
            </li>
          </ul>
          <br />
          <br />
          Your use of such applications and/or third-party websites may be
          subject to additional terms and conditions that outside of our
          control. The use of third-party applications and/or websites is,
          therefore, undertaken at your own risk.
          <br />
          <br />
          <b>What liability is incurred concerning access to the Website?</b>
          <br />
          <br />
          We cannot guarantee that the Website is free from anomalies, errors or
          bugs, nor that they can be corrected, or that the Website can operate
          without interruption or fault, nor that it is compatible with all
          types of equipment.
          <br />
          <br />
          The Website operates via the Internet and over mobile networks that
          are beyond our control. We are not responsible for the unavailability
          of the Website or any difficulty or failure downloading or accessing
          the Content and Services.
          <br />
          <br />
          The Service may be inaccessible at times for maintenance, updates or
          modifications. We cannot be held liable for any interruption of access
          to the Service for maintenance or modifications to all or part of the
          Service.
          <br />
          <br />
          We reserve the right to modify, terminate, suspend or interrupt access
          to all or part of the Website, including the Content and Services,
          without prior notice, in accordance with the law, at any time and for
          any reason, at our sole discretion. We cannot be held liable for the
          unavailability of the Website. We also reserve the right to suspend
          access to the Website, temporarily or permanently, including in the
          case of the termination of the business activities related to the
          provision of the Website, or in the event of collective proceedings.
          <br />
          <br />
          In the event that we are held liable for damages not provided for in
          the CGU, our liability shall be limited to certain, real and direct
          damages only.
          <br />
          <br />
          <h3>8. ENTIRETY</h3>
          These CGU are made up of the entirety of the clauses that constitute
          them. The failure of a party to avail itself at any given time of any
          of the clauses of this agreement cannot be considered as a waiver to
          avail itself of these same clauses at a later time. In the event that
          one of the clauses is deemed null and void by a change in legislation,
          regulation or by a court decision, this will in no way affect the
          validity of and compliance with the CGU.
          <br />
          <br />
          In the event that one of the clauses is deemed null and void by a
          change in legislation, regulation or by a court decision, this will in
          no way affect the validity of and compliance with the CGU.
          <br />
          <br />
          <h3>9. DURATION </h3>
          The CGU apply on the date of application and for the whole duration of
          the Website's operation.
          <br />
          <br />
          <h3>10. APPLICABLE LAW - DISPUTES</h3>
          The general terms and conditions of use are subject to French law. Any
          disputes involved in the implementation or performance of the CGU or
          the interpretation of all or part of the CGU will be subject to an
          attempt to find an amicable agreement. Failing this, any dispute will
          come under the exclusive jurisdiction of the relevant French courts.
          <br />
          <br />
        </div>
      );
    } else if (showInfoPage === "confidentialite") {
      return (
        <div>
          <h1 style={{ fontWeight: "bold" }}>Privacy policy</h1>
          SEB Développement (the “Group”, “us”) attaches great importance to
          protecting your privacy. Within the context of the Group’s business,
          including when you interact with our products, you may generate or be
          prompted to enter information that directly or indirectly concerns you
          ("personal data").
          <br />
          <br />
          In the case of the present website, only Cookies can be filed on your
          device in accordance with the Cookie Policy available hereunder. No
          personal data is collected via the present website.
          <br />
          <br />
          This Privacy Policy does not cover the processing of the personal data
          we collect via channels other than the present website such as other
          websites of the Group, social networks, applications, customer
          services and sales outlets. We ask that you read the specific
          information notices for these services.
          <br />
          <br />
          If you have any questions, please contact the Group at the following
          address:
          <br />
          <br />
          SEB DEVELOPPEMENT
          <br />
          Service Communication Corporate
          <br />
          112, chemin du moulin Caron
          <br />
          69130 Ecully
          <br />
          <br />
          If you have any questions about the processing of your personal data,
          you can also contact the Group Data Protection Officer at the
          following address:
          <a
            href="https://legal.groupeseb.com/Privacy/contact.html"
            target="_blank"
            rel="noreferrer"
          >
            https://legal.groupeseb.com/Privacy/contact.html
          </a>
          .
          <br />
          <br />
          This information notice was updated on December 1, 2022. It may be
          modified from time to time. In this case, the new version of the
          notice will be sent to you using the channel with which the Group
          usually communicates with you.
          <br />
          <br />
        </div>
      );
    } else if (showInfoPage === "cookies") {
      return (
        <div>
          <h1 style={{ fontWeight: "bold" }}>Cookies policy</h1>
          During your use of our sites or applications, Cookies may be installed
          on your device, in particular on your computer, tablet or smartphone.
          <br />
          <br />
          Our use of Cookies is subject to your express, prior, specific
          consent, given before they are installed on your device. We obtain
          your agreement via a banner that includes clear, explicit information
          on the purposes for which the Cookies will be used and information on
          how to object to the use of Cookies.
          <br />
          <br />
          By giving your consent, you agree to use of the Cookies in accordance
          with this policy. If you do not agree to use of the Cookies, you can
          deactivate them by following the instructions below on the page “How
          can I deactivate optional Cookies”.
          <br />
          <br />
          <h3>What is a Cookie?</h3>
          Cookies are text files capable of identifying you as a customer and
          saving your personal preferences (for example your language choices)
          and technical information (including clickstream data).
          <br />
          <br />
          We may also use web tags, also known as “pixel tags” or “clear GIF” or
          similar technology used to check how you use our site and to see which
          pages of our site you visit.
          <br />
          <br />
          <h3>What are Cookies for?</h3>
          We may use Cookies to help us recognize your browser or device, store
          your preferences, provide certain features, and collect information
          about your interactions with us, our content, and our communications.
          For example, when we communicate with you via HTML email, web beacons
          inform us of your activity including whether you have received and
          opened our email, clicked on a link, or otherwise interacted with our
          content, and this information may be combined with other previously
          collected information.
          <br />
          <br />
          <h3>What Cookies do we use?</h3>
          We use different types of Cookies on the site:
          <br />
          <br />
          <h4>1.1. Cookies not subject to your consent</h4>
          “Necessary” Cookies allow you to access the essential functions of the
          site. They are useful to provide, develop, maintain, protect and
          improve our site (e.g. to remember the content of your shopping cart).
          <br />
          <br />
          “Personalization” Cookies are not necessary, but they make it easier
          to use the site and make your experience user friendly (e.g. to retain
          the selected language). You can delete them via the parameters on your
          browser, but you will not be able to use the site correctly without
          these Cookies.
          <br />
          <br />
          <h4>1.2. Cookies needing your consent </h4>
          “Audience measurement” Cookies allow us to analyse traffic,
          performance of our content, , usages, to identify any malfunctions of
          the site and to understand the uses made of our site. This helps us to
          improve your experience as well as the design and content of the site,
          to meet your needs. We use Google Analytics for this purpose. You can
          find information about how Google uses information collected through
          the site by following the link:{" "}
          <a
            href="https://policies.google.com/technologies/partner-sites?hl=en"
            target="_blank"
            rel="noreferrer"
          >
            https://policies.google.com/technologies/partner-sites?hl=en
          </a>
          .
          <br />
          <br />
          <h3>List of third-party Cookies deployed on the site</h3>
          <CookiesPolicy />
          <br />
          <br />
          <h3>How can I deactivate optional Cookies?</h3>
          The first time you visit the site, a banner informs you of the
          presence of these Cookies and you are invited to state your
          preferences.
          <br />
          <br />
          You can also manage installation of each of these Cookies at any time,
          via our cookie manager by clicking on "Manage Cookies" button
          accessible via the "Information" menu. ».
          <br />
          <br />
          You can refuse the installation of optional third-party cookies
          through your browser settings, it is even possible that your browser
          may prevent the installation of optional third-party cookies by
          default. However, we do not guarantee the effectiveness of such
          settings. To ensure you have complete control over the management of
          the cookies deployed on our site we recommend that you use the cookie
          manager{" "}
          <a
            href="https://discovery.groupeseb.com/"
            target="_blank"
            rel="noreferrer"
          >
            https://discovery.groupeseb.com/
          </a>
          . <br />
          <br />
        </div>
      );
    } else if (showInfoPage === "cookies_center") {
      return (
        <div>
          <h1 style={{ fontWeight: "bold" }}>Manage Cookies</h1>
          <div className="switchBox">
            <label className="switch">
              <input type="checkbox" checked disabled />
              <span className="slider round"></span>
            </label>
            <div>Necessary Cookies</div>
          </div>
          <br />
          <br />
          <div className="switchBox">
            <label className="switch">
              <input
                type="checkbox"
                checked={cookies?.consent === "yes"}
                onChange={(e) =>
                  e.target.checked ? handleRgpd("yes") : handleRgpd("no")
                }
              />
              <span className="slider round"></span>
            </label>
            <div>Audience measurement Cookies</div>
          </div>
        </div>
      );
    } else if (showInfoPage === "mentions") {
      return (
        <div>
          <h1 style={{ fontWeight: "bold" }}>Legal notice</h1>
          <i>Applicable as from December 1st, 2022</i>
          <br />
          <br />
          <h3>EDITOR</h3>
          The Site is published by SEB DEVELOPPEMENT, a simplified joint stock
          company with a capital of 3,250,000 Euros, registered in the Lyon
          Trade and Companies Register under number 016 950 842 and whose
          registered office is located at 112 Chemin du Moulin Carron, CS 90 229
          69130 Ecully.
          <br />
          <br />
          Phone: 04 72 18 18 18
          <br />
          <br />
          Director of publication: Mrs. Cathy PIANON - Executive VP, Public
          Affairs & Communication.
          <br />
          <br />
          <h3>HOSTING PROVIDER</h3>
          The Site is hosted on servers located in Europe by Amazon Web Services
          Inc, PO Box 81226, Seattle, WA 981808-1226 - USA.{" "}
          <a
            href="https://aws.amazon.com/fr/compliance/eu-data-protection/"
            target={"_blank"}
            rel="noreferrer"
          >
            https://aws.amazon.com/fr/compliance/eu-data-protection/
          </a>
          .
          <br />
          <br />
          <h3>DATA CONTROLLER FOR THE SITE </h3>
          SEB DEVELOPPEMENT, a simplified joint stock company, whose registered
          office is located at 112 Chemin du Moulin Carron, CS 90229 69130
          Ecully, France, registered in the Lyon Trade and Companies Register
          under number 016 950 842.
          <br />
          <br />
        </div>
      );
    } else if (showInfoPage === "copy") {
      return (
        <div>
          <h1 style={{ fontWeight: "bold" }}>Copyrights</h1>
          &copy; SEB Discovery Groupe SEB
          <br />
          Actors: Lise PEDERSEN and Hugo MORASSO
          <br />
          Production: Digiplay agency
          <br />
          Design and artistic direction: Christophe MAYER
          <br />
          Realization of the filming of the sets: Philippe RIOT
          <br />
          Chief video operator: Emmanuel THERY
          <br />
          Dialogue writing: Christophe MAYER and Philippe RIOT
          <br />
          Compositing / synthesized pictures: Jean-Christophe JARRE
          <br />
          Original music composer: Adélie Prod agency / Nathanaël BERGESE
          <br />
          Royalty free music bank: AudioJungle
          <br />
          Pictures and illustrations: Groupe SEB - Shutterstock - Adobe Stock
          <br />
          <br />
          We apologize to the owners of the rights to any illustrations who we
          tried to reach without success and invite them to come forward.
          <br />
        </div>
      );
    } else if (showInfoPage === "cookies_table") {
      return (
        <div>
          <h1 style={{ fontWeight: "bold" }}>
            List of third-party using Cookies on our website
          </h1>
          <CookiesPolicy />
        </div>
      );
    }
  };

  const InfoPageFR = () => {
    if (showInfoPage === "btn") {
      return (
        <div>
          <div className="containerGrid">
            <div>
              <button onClick={() => setShowInfoPage("cgu")}>
                {language === "fr"
                  ? "Conditons générales d'utilisation"
                  : "General terms and conditions of use"}
              </button>
            </div>
            <div>
              <button onClick={() => setShowInfoPage("confidentialite")}>
                {language === "fr"
                  ? "Politique de confidentialité"
                  : "Privacy policy"}
              </button>
            </div>
            <div>
              <button onClick={() => setShowInfoPage("cookies")}>
                {language === "fr" ? "Politique Cookies" : "Cookies policy"}
              </button>
            </div>
            <div>
              <button onClick={() => setShowInfoPage("cookies_center")}>
                {language === "fr" ? "Gestion des Cookies" : "Manage Cookies"}
              </button>
            </div>
            <div>
              <button onClick={() => setShowInfoPage("mentions")}>
                {language === "fr" ? "Mentions légales" : "Legal notice"}
              </button>
            </div>
            <div>
              <button onClick={() => setShowInfoPage("copy")}>
                {language === "fr" ? "Copyrights" : "Copyrights"}
              </button>
            </div>
          </div>
        </div>
      );
    } else if (showInfoPage === "cgu") {
      return (
        <div>
          <h1 style={{ fontWeight: "bold" }}>
            Conditions générales d'utilisation
          </h1>
          Applicable à compter du 1er décembre 2022
          <br />
          <br />
          <h3>1. PRÉAMBULE</h3>
          Les présentes Conditions Générales d’Utilisation vous renseignent sur
          les règles applicables à l’utilisation du site{" "}
          <a href="https://discovery.groupeseb.com/">
            https://discovery.groupeseb.com/
          </a>{" "}
          ci-après le « Site », édité par la société SEB DEVELOPPEMENT.
          <br />
          <br />
          Le Site a pour vocation de fournir des informations institutionnelles
          et historiques sur le Groupe SEB.
          <br />
          <br />
          Afin de déterminer précisément l’entité juridique responsable pour le
          Site que vous utilisez, nous vous invitons à consulter les{" "}
          <b>Mentions Légales</b> disponibles sur le Site.
          <br />
          <br />
          Les présentes conditions générales d’utilisation (CGU) sont
          susceptibles d’être complétées par des conditions particulières
          propres au Site.
          <br />
          <br />
          Les présentes conditions d’utilisation se réfèrent aux conditions
          supplémentaires suivantes qui s’appliquent également à l’utilisation
          que vous faites de Notre Site :
          <br />- <b>La Politique de confidentialité</b>
          <br />- <b>Mentions légales</b>
          <br />
          <br />
          Nous vous invitons à consulter ces documents supplémentaires
          disponibles sur ce Site.
          <br />
          <h3>2. LEXIQUE</h3>
          <b>A quoi les termes identifiés font-ils référence ?</b>
          <br />
          <br />
          <b>CGU</b> : désigne les présentes conditions générales d’utilisation
          régissant l’utilisation du Site
          <br />
          <br />
          <b>Contenu</b> : désigne l’ensemble des fichiers audio, vidéo, images,
          textes, informations, bases de données, concepts, Marques, logos,
          solutions techniques, graphiques, apparence, structure ainsi que tout
          autre élément protégé ou non par un droit de propriété intellectuelle
          ou par des dispositions légales, existants ou futurs, que nous
          communiquons sur le Site et/ou à votre disposition sur le Site.
          <br />
          <br />
          <b>Marque(s)</b> : désigne les marques de commerce, logos, marques et
          toute autre appellation propriété du Groupe SEB
          <br />
          <br />
          <b>Nous, notre, nos</b> : désigne le Groupe SEB soit l’ensemble des
          sociétés rattachées directement ou indirectement à la société SEB SA.
          <br />
          <br />
          <b>Service(s)</b> : désigne individuellement ou collectivement tout
          service et toutes les fonctions qui y sont associées que nous
          proposons sur le Site sans contrepartie financière ou à titre onéreux.
          <br />
          <br />
          <b>Site</b> : désigne, le cas échéant, le présent site web
          <br />
          <br />
          <b>Vous, votre, vos</b> : désigne toute personne physique ou morale
          qui accède, navigue ou visite, à titre professionnel ou non, le Site.
          <br />
          <br />
          <h3>3. ACCEPTATION ET MODIFICATION DES CGU</h3>
          En accédant au Site, vous acceptez de vous soumettre aux présentes CGU
          et à toutes les conditions contenues ou mentionnées, en vigueur au
          jour de cet accès.
          <br />
          <br />
          Si vous refusez l’une quelconque des obligations ou conditions
          contenues dans les CGU, veuillez ne pas utiliser le Site. Nous pouvons
          modifier, à tout moment, tout ou partie du Site, des Services et/ou
          des CGU.
          <br />
          <br />
          <h3>4. CONDITIONS D’ACCES AU SITE</h3>
          Nous vous concédons un droit non-exclusif, non transmissible, non
          cessible d’utilisation des Contenus du Site aux seules fins d’utiliser
          le Site conformément aux CGU.
          <br />
          <br />
          Vous vous engagez, de manière générale, à respecter les CGU ainsi que
          les obligations légales en vigueur.
          <br />
          <br />
          En particulier, vous vous engagez notamment à respecter les règles
          suivantes :
          <br />
          <br />
          <ul>
            <li>
              Utiliser le Site de manière loyale et conformément à ses finalités
              ;
            </li>
            <li>
              Ne pas copier tout ou partie du Site, ni le/la reproduire, le/la
              vendre, le/la mettre à disposition dans le cadre d’une licence,
              le/la distribuer, le/la céder, le/la modifier, l’adapter, le/la
              traduire, en préparer des œuvres dérivées, le/la décompiler, le/la
              soumettre à des opérations d’ingénierie inverse, le/la
              désassembler ou encore tenter d’en dériver le code source ;
            </li>
            <li>
              Ne pas contourner ou mettre en échec les règles de sécurité ou
              d’utilisation des Contenus qui sont fournies, déployées ou
              appliquées par une fonctionnalité du Site ;
            </li>
            <li>
              Ne pas utiliser un dispositif de type virus, robot, logiciel ou
              sous-programme pour interférer ou essayer d’interférer sur le bon
              fonctionnement du Site ou sur l’utilisation du Site par toute
              autre personne ;
            </li>
            <li>
              Ne pas publier ni introduire d'informations inexactes ou
              trompeuses lors de l'utilisation du Site ;
            </li>
            <li>Ne pas usurper l’identité d’une autre personne ;</li>
            <li>
              Ne pas collecter les informations d'autres utilisateurs, afin de
              les utiliser notamment pour l'envoi de sollicitations commerciales
              ou équivalentes, ou de les intégrer au sein d'un service de
              référencement.
            </li>
          </ul>
          <br />
          <br />
          <h3>5. PROPRIETE INTELLECTUELLE</h3>
          Les Contenus composant le Site et les droits associés sont la
          propriété des sociétés composant le Groupe SEB, notamment, sans que
          cette liste soit limitative, les Marques, designs, apparences,
          structure, concepts, solutions techniques et graphiques, bases de
          données, logiciels et leurs codes sources, ainsi que l’agencement et
          la mise en forme de ces éléments, composant le Site et les Services,
          et/ou étant à la base du fonctionnement du Site et des Services et/ou
          transitant par le Site et les Services, et/ou mis à disposition par le
          biais du Site et des Services.
          <br />
          <br />
          Toute reproduction, représentation, adaptation, modification,
          publication, transmission, dénaturation, intégration dans un autre
          site, exploitation commerciale et/ou réutilisation totale ou partielle
          du Site et/ou des Contenus, par tout procédé, et sur tout support, est
          strictement interdite et pourrait notamment constituer acte de
          contrefaçon sanctionné par le Code de la Propriété Intellectuelle ou
          toute autre réglementation applicable.
          <br />
          <br />
          <h3>6. DONNEES PERSONNELLES</h3>
          La collecte éventuelle et le traitement de Vos données personnelles
          sont régis par la <b>Politique de confidentialité</b> du présent Site.
          Nous vous invitons à la consulter.
          <br />
          <br />
          <h3>7. RESPONSABILITE</h3>
          <b>Qui est responsable des liens vers d’autres sites web ?</b>
          <br />
          <br />
          Le Site peut donner accès à d'autres sites Internet et/ou applications
          via des liens hypertextes. Nous ne sommes pas gestionnaires de ces
          sites et applications et ne pouvons en contrôler le contenu.
          <br />
          <br />
          En conséquence, nous ne pouvons en aucun cas être tenus pour
          responsables :
          <br />
          <br />
          <ul>
            <li>
              Du contenu et des services mis à disposition sur ces applications
              et/ou sites tiers, ou{" "}
            </li>
            <li>
              Des éventuelles collectes et transmissions de données,
              installation de cookies ou tout autre procédé tendant aux mêmes
              fins, effectués par ces sites ou applications, ou
            </li>
            <li>
              Des problèmes de sécurité ou d'intégrité des données, matériels et
              logiciels que vous pourriez rencontrer ainsi que de toute
              conséquence ou dommage qui pourrait résulter du fait de
              l'utilisation de ces applications et/ou sites tiers.
            </li>
          </ul>
          <br />
          <br />
          Votre utilisation de ces applications et/ou sites tiers peut être
          soumise à des modalités additionnelles que nous ne maîtrisons pas.
          L’usage des applications et/ou sites tiers se fait donc à vos risques
          et périls.
          <br />
          <br />
          <b>
            Quelle est la responsabilité mise en œuvre pour accéder au Site ?
          </b>
          <br />
          <br />
          Nous ne pouvons garantir que le Site est exempt(e) d'anomalies,
          d'erreurs ou de bugs, ni que ceux-ci pourront être corrigés, ni que le
          Site fonctionne sans interruption ou panne, ni encore qu'il/elle est
          compatible avec tout type de matériel.
          <br />
          <br />
          Le Site fonctionne au moyen d’Internet et de réseaux mobiles qui sont
          hors de notre contrôle. Nous ne sommes pas responsables de
          l’indisponibilité du Site ou de toute difficulté ou incapacité à
          télécharger ou à accéder aux Contenus et Services.
          <br />
          <br />
          Le Service pourra être inaccessible pendant les périodes de
          maintenance, de mise à jour ou de modifications. Notre responsabilité
          ne saurait être recherchée en cas d’interruption d’accès au Service du
          fait d’opération de maintenance de mise à jour ou de modifications de
          tout ou partie du Service.
          <br />
          <br />
          Nous nous réservons le droit de modifier, de résilier, de suspendre ou
          d'interrompre à tout moment, pour toutes raisons et à notre seule
          discrétion, l'accès à tout ou partie du Site, y compris notamment les
          Contenus et les Services sans avis préalable, dans le respect des
          dispositions légales. Notre responsabilité ne pourra en aucun cas être
          recherchée en cas d’indisponibilité du Site. Nous nous réservons par
          ailleurs le droit d'interrompre temporairement ou définitivement
          l'accès au Site, notamment en cas de cessation de l'activité liée à la
          mise à disposition du Site, ou en cas de procédure collective.
          <br />
          <br />
          Dans l’hypothèse où nous serions tenus responsables d'un dommage non
          prévu dans les CGU, notre responsabilité sera limitée aux seuls
          dommages certains, réels et directs.
          <br />
          <br />
          <h3>8. INTEGRALITE </h3>
          Les présentes CGU sont constituées de l'intégralité des clauses qui
          les composent. Le fait de ne pas se prévaloir à un moment donné de
          l'une quelconque des clauses des présentes, ne peut valoir
          renonciation à se prévaloir ultérieurement de ces mêmes clauses.
          <br />
          <br />
          Dans l’hypothèse où l’une des clauses serait nulle et non avenue par
          un changement de législation, de réglementation ou par une décision de
          justice, cela ne saurait en aucun cas affecter la validité et le
          respect des présentes CGU.
          <br />
          <br />
          <h3>9. DUREE </h3>
          Les présentes CGU s’appliquent à compter de la date d’application et
          pendant toute la durée d’exploitation du Site.
          <br />
          <br />
          <h3>10. DROIT APPLICABLE - LITIGES</h3>
          Les présentes conditions générales d’utilisation sont soumises au
          droit français. Tout litige intervenant sur la mise en œuvre,
          l’exécution des présentes CGU ou à l’occasion de l’interprétation de
          tout ou partie des CGU fera l’objet d’une recherche d’accord amiable.
          A défaut, tout litige relèvera de la compétence exclusive des
          tribunaux français compétents.
          <br />
          <br />
        </div>
      );
    } else if (showInfoPage === "confidentialite") {
      return (
        <div>
          <h1 style={{ fontWeight: "bold" }}>Politique de confidentialité</h1>
          SEB Développement (le « Groupe », « nous ») attache une grande
          importance à la protection de votre vie privée. Dans le cadre des
          activités du Groupe, notamment lorsque vous interagissez avec nous
          et/ou avec nos produits, nous traitons des informations qui peuvent
          vous concerner directement ou indirectement (« données à caractère
          personnel »).
          <br />
          <br />
          Dans le cas du présent site web, seuls des Cookies peuvent être
          déposés sur votre terminal conformément à la Politique Cookies
          accessible ci-dessous. Aucune donnée à caractère personnel n’est
          collectée via le présent site web.
          <br />
          <br />
          Cette Politique de Confidentialité ne couvre pas les traitements de
          données à caractère personnel que nous collectons via d’autres canaux
          que le présent site web, comme les sites Internet du Groupe, les
          réseaux sociaux, les applications, le service client et les points de
          vente. Nous vous invitons, pour l’ensemble de ces services, à lire les
          notices d’information dédiées.
          <br />
          <br />
          Pour toute question, vous pouvez contacter le Groupe à l’adresse
          suivante :
          <br />
          <br />
          SEB DEVELOPPEMENT
          <br />
          Service Communication Corporate
          <br />
          112, chemin du moulin Caron
          <br />
          69130 Ecully
          <br />
          <br />
          Si vous avez des questions concernant le traitement de vos données à
          caractère personnel, vous pouvez également contacter le délégué à la
          protection des données du groupe à l’adresse suivante :
          <a
            href="https://legal.groupeseb.com/Privacy/contact.html"
            target="_blank"
            rel="noreferrer"
          >
            https://legal.groupeseb.com/Privacy/contact.html
          </a>
          .
          <br />
          <br />
          La présente notice d’information a été mise à jour le 1er décembre
          2022. Elle peut faire l’objet de modifications de temps à autre. Dans
          ce cas, une nouvelle version de cette notice vous sera communiquée par
          le canal que le Groupe utilise habituellement pour communiquer avec
          vous.
          <br />
          <br />
        </div>
      );
    } else if (showInfoPage === "cookies") {
      return (
        <div>
          <h1 style={{ fontWeight: "bold" }}>Politique Cookies</h1>
          Au cours de votre utilisation du site, des Cookies peuvent être
          déposés sur votre terminal et notamment votre ordinateur, votre
          tablette ou encore votre smartphone.
          <br />
          <br />
          Notre utilisation des Cookies est soumise à votre consentement exprès,
          préalable et spécifique, avant qu’ils ne puissent être déposés sur
          votre terminal. Nous obtenons votre accord par le biais d’une bannière
          qui inclut d’une part, une information claire et explicite sur les
          finalités pour lesquelles les Cookies sont utilisés et d’autre part,
          une information sur les moyens de s’opposer à l’utilisation de
          Cookies.
          <br />
          <br />
          En donnant votre consentement, vous acceptez l’utilisation de Cookies
          conformément à la présente politique. Si vous n’acceptez pas
          l’utilisation des Cookies, vous pouvez les désactiver en suivant les
          instructions ci-dessous à la rubrique « Comment désactiver les Cookies
          optionnels ».
          <br />
          <br />
          <h3>Qu’est-ce qu’un Cookie ?</h3>
          Les Cookies se présentent sous forme de fichiers textes capables de
          vous identifier comme client et de sauvegarder vos préférences
          personnelles (par exemple vos choix linguistiques) ainsi que des
          informations d'ordre technique (y compris des données de type
          parcours).
          <br />
          <br />
          Par ailleurs, nous pouvons également utiliser des balises web, aussi
          appelées « pixel web » ou « Clear GIF » ou des technologies similaires
          qui servent à vérifier la façon dont vous utilisez notre site et à
          voir quelles pages de notre site vous visitez.
          <br />
          <br />
          <h3>A quoi sert un Cookie ?</h3>
          Nous pouvons utiliser des Cookies pour nous aider à reconnaître votre
          navigateur ou votre appareil, conserver vos préférences, fournir
          certaines fonctionnalités, et collecter des informations sur vos
          interactions avec nous, notre contenu, et nos communications. Par
          exemple, lorsque nous communiquons avec vous par email HTML, les
          balises web nous informent de votre activité y compris si vous avez
          reçu et ouvert notre email, cliqué sur un lien, ou interagi de toute
          autre manière avec notre contenu, et ces informations peuvent être
          associées avec d’autres informations collectées précédemment.
          <br />
          <br />
          <h3>Quels Cookies nous utilisons ?</h3>
          Nous utilisons différents types de Cookies sur le site :
          <br />
          <br />
          <h4>1.1. Les Cookies non soumis à votre consentement</h4>
          Les Cookies dits « nécessaires » vous permettent de bénéficier des
          fonctions essentielles du site. Ils sont nécessaires pour fournir,
          développer, maintenir, protéger et améliorer notre site (par exemple
          garder en mémoire le contenu de votre panier d'achat). Les Cookies de
          "personnalisation" facilitent l'utilisation du site et rendent votre
          expérience plus agréable (par exemple pour retenir le choix de la
          langue). Vous pouvez les supprimer grâce aux paramètres de votre
          navigateur mais sans ces Cookies vous ne pourrez pas correctement
          utiliser le site.
          <br />
          <br />
          <h4>1.2. Les Cookies nécessitant votre consentement</h4>
          Les Cookies de « <b>mesure d’audience</b> » permettent d’analyser le
          trafic, la performance de nos contenus, les usages, de repérer les
          dysfonctionnements du site et de comprendre les usages faits de notre
          site. Cela nous permet d'améliorer votre expérience ainsi que la
          conception et le contenu du site pour répondre à vos besoins. Nous
          utilisons pour cela Google Analytics. Vous pouvez trouver les
          informations sur la façon dont Google utilise les informations
          collectées via le site en suivant le lien :
          <a
            href="https://policies.google.com/technologies/partner-sites"
            target="_blank"
            rel="noreferrer"
          >
            https://policies.google.com/technologies/partner-sites
          </a>
          .
          <br />
          <br />
          <h3>Liste des Cookies tiers déposés sur le site :</h3>
          <CookiesPolicy />
          <br />
          <br />
          <h3>Comment désactiver les Cookies optionnels ?</h3>
          Lors de votre première visite sur le site une bannière vous informe de
          la présence de ces Cookies et vous invite à indiquer votre choix.
          <br />
          <br />
          De plus, vous pouvez à tout moment gérer l’utilisation de chacun de
          ces Cookies via notre gestionnaire de Cookies en cliquant sur le
          bouton « Gestion des Cookies » accessible via le menu « Informations
          ».
          <br />
          <br />
          Vous pouvez refuser l’installation des Cookies optionnels tiers grâce
          aux paramètres de votre navigateur, il est même possible que votre
          navigateur empêche l’installation de Cookies optionnels de tiers par
          défaut. Cependant, nous ne garantissons pas l’effectivité de tels
          paramétrages. Pour vous assurer un contrôle total sur la gestion des
          Cookies déployés sur notre site, nous vous recommandons d’utiliser le
          gestionnaire de Cookies{" "}
          <a
            href="https://discovery.groupeseb.com/"
            target="_blank"
            rel="noreferrer"
          >
            https://discovery.groupeseb.com/
          </a>
          . <br />
          <br />
        </div>
      );
    } else if (showInfoPage === "cookies_center") {
      return (
        <div>
          <h1 style={{ fontWeight: "bold" }}>Gestion des Cookies</h1>
          <div className="switchBox">
            <label className="switch">
              <input type="checkbox" checked disabled />
              <span className="slider round"></span>
            </label>
            <div>Cookies « fonctionnels »</div>
          </div>
          <br />
          <br />
          <div className="switchBox">
            <label className="switch">
              <input
                type="checkbox"
                checked={cookies?.consent === "yes"}
                onChange={(e) =>
                  e.target.checked ? handleRgpd("yes") : handleRgpd("no")
                }
              />
              <span className="slider round"></span>
            </label>
            <div>Cookies « mesure d’audience »</div>
          </div>
        </div>
      );
    } else if (showInfoPage === "mentions") {
      return (
        <div>
          <h1 style={{ fontWeight: "bold" }}>Mentions légales</h1>
          <i>Applicables à compter du 1er décembre 2022</i>
          <br />
          <br />
          <h3>EDITEUR</h3>
          Le Site est édité par la société SEB DEVELOPPEMENT, société par
          actions simplifiée au capital de 3 250 000 Euros, immatriculée au RCS
          de Lyon sous le numéro 016 950 842 et dont le siège social est situé
          112 Chemin du Moulin Carron, CS 90 229 69130 Ecully.
          <br />
          <br />
          Tél : 04 72 18 18 18
          <br />
          <br />
          Directeur de la publication : Mme Cathy PIANON – Directrice Générale
          Affaires Publiques et Communication.
          <br />
          <br />
          <h3>HEBERGEMENT</h3>
          Le Site est hébergé sur les serveurs localisés en Europe de Amazon Web
          Services Inc, PO Box 81226, Seattle, WA 981808-1226 – USA.
          <a
            href="https://aws.amazon.com/fr/compliance/eu-data-protection/"
            target={"_blank"}
            rel="noreferrer"
          >
            https://aws.amazon.com/fr/compliance/eu-data-protection/
          </a>
          .
          <br />
          <br />
          <h3>RESPONSABLE DE TRAITEMENT POUR LE SITE</h3>
          SEB DEVELOPPEMENT, société par actions simplifiée, dont le siège
          social est situé 112 Chemin du Moulin Carron, CS 90229 69130 Ecully,
          France, immatriculée au RCS de Lyon sous le numéro 016 950 842.
          <br />
          <br />
        </div>
      );
    } else if (showInfoPage === "copy") {
      return (
        <div>
          <h1 style={{ fontWeight: "bold" }}>Copyrights</h1>
          &copy; SEB Discovery Groupe SEB
          <br />
          Comédiens : Lise PEDERSEN et Hugo MORASSO
          <br />
          Production : Agence Digiplay
          <br />
          Conception et direction artistique : Christophe MAYER
          <br />
          Réalisation du tournage plateaux : Philippe RIOT
          <br />
          Chef opérateur : Emmanuel THERY
          <br />
          Dialogues plateaux : Christophe MAYER et Philippe RIOT
          <br />
          Compositing / images de synthèse : Jean-Christophe JARRE
          <br />
          Compositeur musiques originales : Agence Adélie Prod / Nathanaël
          BERGESE
          <br />
          Banque de musiques libre de droits : AudioJungle
          <br />
          Images et illustrations : Groupe SEB - Shutterstock - Adobe Stock
          <br />
          <br />
          Que les propriétaires des droits des illustrations que nous n’avons
          pas réussi à contacter trouvent ici nos excuses et se fassent
          connaître.
          <br />
          <br />
        </div>
      );
    } else if (showInfoPage === "cookies_table") {
      return (
        <div>
          <h1 style={{ fontWeight: "bold" }}>
            Liste des tiers utilisant des Cookies sur notre site
          </h1>
          <CookiesPolicy />
        </div>
      );
    }
  };

  const CookiesPolicy = () => {
    if (language === "fr") {
      return (
        <table>
          <thead>
            <tr>
              <th>Nom du cookie</th>
              <th>Durée</th>
              <th>Catégorie</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>_ga</td>
              <td>2 ans</td>
              <td>Statistiques</td>
              <td>
                Ce cookie est associé à Google Analytics et est utilisé pour
                identifier et distinguer un utilisateur. Ces cookies gardent en
                mémoire un identifiant client unique (ID client) qui est généré
                de façon aléatoire. Il est utilisé pour calculer les
                interactions des utilisateurs (visites, données concernant les
                utilisateurs, sessions et sources de trafic)
              </td>
            </tr>
            <tr>
              <td>_gid</td>
              <td>24 heures</td>
              <td>Statistiques</td>
              <td>
                Ce cookie est associé à Google Analytics et est utilisé pour
                identifier un même utilisateur au cours d’une seule et même
                journée.
              </td>
            </tr>
            <tr>
              <td>_gat</td>
              <td>1 minute</td>
              <td>Statistiques</td>
              <td>
                Ce cookie est associé à Google Analytics et est utilisé pour
                faire la distinction entre les différents objets de suivi créés
                au cours de la session. Le cookie se met à jour chaque fois
                qu’il transmet les données à Google Analytics.
              </td>
            </tr>
          </tbody>
        </table>
      );
    } else {
      return (
        <table>
          <thead>
            <tr>
              <th>Cookie name</th>
              <th>Duration</th>
              <th>Category</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>_ga</td>
              <td>2 years</td>
              <td>Statistics</td>
              <td>
                This cookie is associated with Google Analytics and is used to
                identify and distinguish users. These cookies store a unique
                customer identifier (customer ID) that is randomly generated. It
                is used to measure user interactions (visits, user data,
                sessions and traffic sources)
              </td>
            </tr>
            <tr>
              <td>_gid</td>
              <td>24 hours</td>
              <td>Statistics</td>
              <td>
                This cookie is associated with Google Analytics and is used to
                identify a user during the same day.
              </td>
            </tr>
            <tr>
              <td>_gat</td>
              <td>1 minute</td>
              <td>Statistics</td>
              <td>
                This cookie is associated with Google Analytics and is used to
                distinguish between different tracking objects created during
                the session. The cookie is updated each time it transmits data
                to Google Analytics.
              </td>
            </tr>
          </tbody>
        </table>
      );
    }
  };

  return (
    <div className="App">
      {screenOrientation?.includes("portrait") ? (
        <div className="ScreenOrientation">
          <Lottie animationData={animationOrientation} />
        </div>
      ) : (
        <video
          id="welcome"
          ref={videoRef}
          autoPlay
          playsInline
          loop={videoLoop}
          muted={videoMuted}
          preload="metadata"
          poster={`/images/posters/step${step}-min.png`}
          style={{
            background:
              "transparent url(./images/posters/step" +
              step +
              "-min.png) 100% 100% / cover no-repeat",
          }}
          onClick={step < 4 ? handleStep : null}
          onMouseMove={handleMouseMove}
          onTimeUpdate={handleTimeUpdate}
          onEnded={handleStep}
          onMouseOver={hideMenu}
          onContextMenu={e => e.preventDefault()}
        >
          <source
            id="mp4"
            src={`/videos/${video}.mp4#t=0.001`}
            type="video/mp4"
          />
          <p>Your user agent does not support the HTML5 Video element.</p>
        </video>
      )}

      <div id="interface-logo" onClick={() => handleStep(1)}>
        <img src={Logo} width="100%" alt="Logo SEB" />
      </div>
      <div
        onMouseOver={showMenu}
        onClick={handleMenu}
        style={{
          position: "absolute",
          bottom: 0,
          left: "5vw",
          width: "60px",
          height: "300px",
          background: "transparent",
          zIndex: 4,
        }}
      ></div>
      {displayMenuButton1 ? (
        <div id="interface-menu-button1" className="slide-in-bottom1">
          <div className="button-wrap-1 example">
            <div
              onClick={(e) => {
                e.preventDefault();
                setLanguage(language === "en" ? "fr" : "en");
              }}
              className="clicker tooltip"
            >
              <img
                src={language === "en" ? FlagEn : FlagFr}
                alt="Language"
                onMouseOver={handleFlagHover}
                onMouseOut={handleFlagOut}
                className="flag-btn"
              />
              <span className="tooltiptext2">
                {language === "fr" ? "Langue" : "Language"}
              </span>
            </div>
            <div className="circle"></div>
          </div>
        </div>
      ) : null}
      {displayMenuButton2 ? (
        <div id="interface-menu-button2" className="slide-in-bottom2">
          <div className="button-wrap-1 example">
            <div
              onClick={(e) => {
                e.preventDefault();
                setDisplayTimeline(!displayTimeline);
              }}
              className={
                displayTimeline
                  ? "clicker timeline-btn-active tooltip"
                  : "clicker tooltip"
              }
            >
              <img
                src={displayTimeline ? TimelineWhite : Timeline}
                alt="Timeline"
                className="timeline-btn"
              />
              <span className="tooltiptext">
                {language === "fr" ? "Périodes" : "Timeline"}
              </span>
            </div>
            <div className="circle"></div>
          </div>
        </div>
      ) : null}
      {displayMenuButton3 ? (
        <div id="interface-menu-button3" className="slide-in-bottom3">
          <div className="button-wrap-1 example">
            <div
              onClick={handleInfos}
              className={
                infos
                  ? "clicker timeline-btn-active tooltip"
                  : "clicker tooltip"
              }
            >
              <FontAwesomeIcon
                icon={faInfo}
                size="lg"
                style={{ fontSize: "1.5rem" }}
              />
              <span className="tooltiptextIcon">
                {language === "fr" ? "Informations" : "Information"}
              </span>
            </div>
            <div className="circle"></div>
          </div>
        </div>
      ) : null}
      <div id="interface-menu">
        <img src={Home} width="100%" alt="Menu" />
      </div>

      {step === 1 && !overlay ? (
        <div id="interface-play">
          <FontAwesomeIcon
            icon={faPlayCircle}
            size="lg"
            color="#cc0000"
            onClick={() => handleStep("play")}
            className="skip"
            style={{
              cursor: "pointer",
              /*background: 'white',*/ width: "100px",
              height: "100px",
              padding: "8px 8px 8px 10px",
              borderRadius: "30px",
              left: "50vw",
              fontWeight: "bold",
              color: "rgba(255, 255, 255, 0.8)",
            }}
          />
        </div>
      ) : null}
      {displayTimeline ? (
        <div id="interface-timeline">
          <div className="tooltip">
            <FontAwesomeIcon
              icon={faChevronLeft}
              size="lg"
              color="#cc0000"
              onClick={() => {
                if (step > 5) {
                  handleStep(step - 1);
                } else {
                  handleStep(5);
                }
              }}
              style={{
                cursor: "pointer",
                background: "white",
                width: "30px",
                height: "30px",
                padding: "6px",
                borderRadius: "30px",
                left: window.innerWidth < 900 ? "35vw" : "41vw",
              }}
            />
            <span className="tooltiptextTimeline">
              {language === "fr" ? "Période précédente" : "Previous period"}
            </span>
          </div>
          <div style={{}}>
            <div className="tooltip">
              <FontAwesomeIcon
                icon={faCircle}
                size="lg"
                color={step === 5 ? "#cc0000" : "grey"}
                onClick={() => {
                  handleStep(5);
                }}
                style={{
                  cursor: "pointer",
                  background: "white",
                  width: "16px",
                  height: "16px",
                  padding: "2px",
                  borderRadius: "30px",
                  left: window.innerWidth < 900 ? "41vw" : "46vw",
                }}
              />
              <span className="tooltiptextTimeline">1857-1951</span>
            </div>
            <div className="tooltip">
              <FontAwesomeIcon
                icon={faCircle}
                size="lg"
                color={step === 6 ? "#cc0000" : "grey"}
                onClick={() => {
                  handleStep(6);
                }}
                style={{
                  cursor: "pointer",
                  background: "white",
                  width: "16px",
                  height: "16px",
                  padding: "2px",
                  borderRadius: "30px",
                  left: window.innerWidth < 900 ? "45vw" : "49vw",
                }}
              />
              <span className="tooltiptextTimeline">1952-1972</span>
            </div>
            <div className="tooltip">
              <FontAwesomeIcon
                icon={faCircle}
                size="lg"
                color={step === 7 ? "#cc0000" : "grey"}
                onClick={() => {
                  handleStep(7);
                }}
                style={{
                  cursor: "pointer",
                  background: "white",
                  width: "16px",
                  height: "16px",
                  padding: "2px",
                  borderRadius: "30px",
                  left: window.innerWidth < 900 ? "49vw" : "52vw",
                }}
              />
              <span className="tooltiptextTimeline">1973-1990</span>
            </div>
            <div className="tooltip">
              <FontAwesomeIcon
                icon={faCircle}
                size="lg"
                color={step === 8 ? "#cc0000" : "grey"}
                onClick={() => {
                  handleStep(8);
                }}
                style={{
                  cursor: "pointer",
                  background: "white",
                  width: "16px",
                  height: "16px",
                  padding: "2px",
                  borderRadius: "30px",
                  left: window.innerWidth < 900 ? "53vw" : "55vw",
                }}
              />
              <span className="tooltiptextTimeline">1991-2000</span>
            </div>
            <div className="tooltip">
              <FontAwesomeIcon
                icon={faCircle}
                size="lg"
                color={step === 9 ? "#cc0000" : "grey"}
                onClick={() => {
                  handleStep(9);
                }}
                style={{
                  cursor: "pointer",
                  background: "white",
                  width: "16px",
                  height: "16px",
                  padding: "2px",
                  borderRadius: "30px",
                  left: window.innerWidth < 900 ? "57vw" : "58vw",
                }}
              />
              <span className="tooltiptextTimeline">2001-2010</span>
            </div>
            <div className="tooltip">
              <FontAwesomeIcon
                icon={faCircle}
                size="lg"
                color={step === 10 ? "#cc0000" : "grey"}
                onClick={() => {
                  handleStep(10);
                }}
                style={{
                  cursor: "pointer",
                  background: "white",
                  width: "16px",
                  height: "16px",
                  padding: "2px",
                  borderRadius: "30px",
                  left: window.innerWidth < 900 ? "61vw" : "61vw",
                }}
              />
              <span className="tooltiptextTimeline">
                {language === "fr" ? "Depuis" : "Since"} 2010
              </span>
            </div>
          </div>
          <div className="tooltip">
            <FontAwesomeIcon
              icon={faChevronRight}
              size="lg"
              color="#cc0000"
              onClick={() => {
                if (step < 10) {
                  handleStep(step + 1);
                } else {
                  handleStep(10);
                }
              }}
              style={{
                cursor: "pointer",
                background: "white",
                width: "30px",
                height: "30px",
                padding: "6px",
                borderRadius: "30px",
                left: window.innerWidth < 900 ? "67vw" : "66vw",
                fontWeight: "bold",
              }}
            />
            <span className="tooltiptextTimeline">
              {language === "fr" ? "Période suivante" : "Next period"}
            </span>
          </div>
        </div>
      ) : null}

      {!screenOrientation?.includes("portrait") ? (
        <>
          <div id="interface-mute">
            <div className="button-wrap-1 example">
              <div
                onClick={() => handleUnMuted(!videoMuted)}
                className={
                  infos
                    ? "clicker timeline-btn-active clicker-right"
                    : "clicker clicker-right"
                }
              >
                <FontAwesomeIcon
                  icon={videoMuted ? faVolumeXmark : faVolumeHigh}
                  size="lg"
                  color="#cc0000"
                  className="skip"
                  style={{ fontSize: "1.5rem" }}
                />
              </div>
            </div>
          </div>
          <div id="interface-pause">
            <div className="button-wrap-1 example">
              <div
                onClick={() => handlePaused(videoPaused)}
                className={
                  infos
                    ? "clicker timeline-btn-active clicker-right"
                    : "clicker clicker-right"
                }
              >
                <FontAwesomeIcon
                  icon={videoPaused ? faPlay : faPause}
                  size="lg"
                  color="#cc0000"
                  className="skip"
                  style={{ fontSize: "1.5rem" }}
                />
              </div>
            </div>
          </div>
          <div id="interface-backward">
            <div className="button-wrap-1 example">
              <div
                onClick={handleBackward}
                className={
                  infos
                    ? "clicker timeline-btn-active clicker-right"
                    : "clicker clicker-right"
                }
              >
                <img src={PictoArriere} alt="-10s" className="backward-btn" />
              </div>
            </div>
          </div>
          <div id="interface-forward">
            <div className="button-wrap-1 example">
              <div
                onClick={handleForward}
                className={
                  infos
                    ? "clicker timeline-btn-active clicker-right"
                    : "clicker clicker-right"
                }
              >
                <img src={PictoAvant} alt="+10s" className="forward-btn" />
              </div>
            </div>
          </div>
        </>
      ) : null}
      {overlay && backPage === null ? (
        <div id="overlay">
          <div
            style={{
              width: "60vw",
              height: "40vh",
              backgroundColor: "rgba(78, 77, 77, 0.6)",
              zIndex: 5,
              position: "fixed",
              top: "50%",
              left: "50%",
              marginTop: "-20vh",
              marginLeft: "-30vw",
              borderRadius: "20px",
            }}
          >
            <div
              style={{
                width: "58vw",
                height: "35vh",
                overflow: "auto",
                margin: "5px",
                padding: "5px",
                textAlign: "center",
                color: "#dadada",
                MsOverflowStyle: "none",
                scrollbarWidth: "none",
              }}
            >
              {language === "fr" ? (
                <div>
                  Bienvenue sur le site <b>SEB Discovery</b>&#8239;!
                  <br />
                  Vous allez découvrir l'histoire du Groupe SEB et voyager dans
                  le temps de 1857 à nos jours&#8239;!
                  <br />
                  Bon&#8239;&#8239;voyage&#8239;!
                  <br />
                  <br />
                  Nous et nos{" "}
                  <a
                    href="#"
                    onClick={() => {
                      setBackPage("overlay");
                      setInfos(true);
                      setShowInfoPage("cookies_table");
                    }}
                  >
                    partenaires
                  </a>{" "}
                  souhaitons utiliser des cookies internes et de tiers ou des
                  technologies similaires (collectivement les
                  «&#8239;Cookies&#8239;») pour collecter certaines{" "}
                  <a
                    href="#"
                    onClick={() => {
                      setBackPage("overlay");
                      setInfos(true);
                      setShowInfoPage("confidentialite");
                    }}
                  >
                    de vos données
                  </a>{" "}
                  pour effectuer des analyses. <br />
                  Vous pouvez accepter ou refuser ce qui précède en cliquant sur
                  «&#8239;Accepter tous les Cookies&#8239;» ou «&#8239;Refuser
                  les Cookies non nécessaires&#8239;». Veuillez noter que si
                  vous refusez les Cookies, nous n'utiliserons que les Cookies
                  nécessaires au bon fonctionnement du site Web. <br />
                  Cliquez sur «&#8239;Mes choix&#8239;» pour plus d'informations
                  sur les catégories de Cookies. Vous pouvez changer d'avis à
                  tout moment dans notre{" "}
                  <a
                    href="#"
                    onClick={() => {
                      setBackPage("overlay");
                      setInfos(true);
                      setShowInfoPage("cookies_center");
                    }}
                  >
                    centre de préférences
                  </a>
                  . Vous pouvez en savoir plus en lisant notre{" "}
                  <a
                    href="#"
                    onClick={() => {
                      setBackPage("overlay");
                      setInfos(true);
                      setShowInfoPage("cookies");
                    }}
                  >
                    politique relative aux Cookies
                  </a>
                  .
                  <br />
                </div>
              ) : (
                <div>
                  Welcome to the <b>SEB Discovery</b> site&#8239;!
                  <br />
                  You will discover the history of Groupe SEB and travel back in
                  time from 1857 to the present day&#8239;!
                  <br />
                  &#8239;Have&#8239;&#8239;a&#8239;&#8239;good&#8239;&#8239;trip&#8239;!
                  <br />
                  <br />
                  We, our affiliates and our{" "}
                  <a
                    href="#"
                    onClick={() => {
                      setBackPage("overlay");
                      setInfos(true);
                      setShowInfoPage("cookies_table");
                    }}
                  >
                    partners
                  </a>{" "}
                  would like to use first and third-party cookies or similar
                  technologies (collectively &#8239;“Cookies”&#8239;) to collect
                  some{" "}
                  <a
                    href="#"
                    onClick={() => {
                      setBackPage("overlay");
                      setInfos(true);
                      setShowInfoPage("confidentialite");
                    }}
                  >
                    of your data
                  </a>{" "}
                  to perform analytics.
                  <br />
                  You can agree or decline the above by clicking on
                  &#8239;"Accept all Cookies"&#8239; or &#8239;“Refuse
                  non-necessary Cookies”.&#8239;Please note that if you refuse
                  Cookies, we will only use Cookies necessary for the effective
                  operation of the website.
                  <br />
                  Click on &#8239;“My Choices”&#8239; for more information on
                  the Cookies categories. You can change your mind at any time
                  from our{" "}
                  <a
                    href="#"
                    onClick={() => {
                      setBackPage("overlay");
                      setInfos(true);
                      setShowInfoPage("cookies_center");
                    }}
                  >
                    preference center
                  </a>
                  . You can find out more by reading our{" "}
                  <a
                    href="#"
                    onClick={() => {
                      setBackPage("overlay");
                      setInfos(true);
                      setShowInfoPage("cookies");
                    }}
                  >
                    Cookies policy
                  </a>
                  .
                  <br />
                </div>
              )}
              <br />
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    width: "49%",
                    display: "inline-block",
                    textAlign: "left",
                  }}
                >
                  <button onClick={() => handleRgpd("no")}>
                    {language === "fr"
                      ? "Refuser les Cookies non nécessaires"
                      : "Refuse non-necessary Cookies"}
                  </button>
                </div>
                <div
                  style={{
                    width: "49%",
                    display: "inline-block",
                    textAlign: "right",
                  }}
                >
                  <button onClick={() => handleRgpd("yes")}>
                    {language === "fr"
                      ? "Accepter tous les Cookies"
                      : "Accept all Cookies"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {/* <div id="overlay" onClick={handleRgpd}>
          <div
            style={{
              display: "flex",
              flex: 1,
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handleRgpd}
          >
            <div
              style={{
                margin: 0,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                height: "100vh",
              }}
            >
              <img src={RGPD} alt="fond" style={{ height: "100%" }} />
            </div>
          </div>
        </div> 
      ) : null}*/}
      {infos ? (
        <div id="overlay">
          <div
            style={{
              width: "60vw",
              height: "40vh",
              backgroundColor: "rgba(78, 77, 77, 0.6)",
              zIndex: 5,
              position: "fixed",
              top: "50%",
              left: "50%",
              marginTop: "-20vh",
              marginLeft: "-30vw",
              borderRadius: "20px",
            }}
          >
            <div
              style={{
                width: "58vw",
                height: "35vh",
                overflow: "auto",
                margin: "5px",
                padding: "5px",
                textAlign: "left",
                color: "#dadada",
                "-ms-overflow-style": "none",
                "scrollbar-width": "none",
              }}
            >
              <div style={{ textAlign: "left" }} className="close">
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  size="lg"
                  color="#cc0000"
                  style={{ fontSize: "1.5rem" }}
                  onClick={handleChangePage}
                />
              </div>
              {language === "fr" ? <InfoPageFR /> : <InfoPageEN />}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );

  //return screenOrientation === "portrait" ? <ScreenMessage /> : <AppContent />;
}

export default App;
